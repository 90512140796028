import React from 'react';
import Layout from '../components/layout';
import { handleAuthentication } from '../utils/auth';

const Callback = () => {
  handleAuthentication();

  return (
    <Layout>
      <div className="text-center">
        <h2>Loading...</h2>
      </div>
    </Layout>
  );
};

export default Callback;
